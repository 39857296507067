/* * {
  border: 1px solid blue;
} */

.lastSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 50px 0;
}
.lastSection .terms-conditions {
  width: 70%;
  min-width: 300px;
  max-width: 600px;
  padding: 20px;
}
.lastSection .terms-conditions h2 {
  color: #ff7f23;
  font-size: 30px;
  font-weight: bold;
}
.lastSection .terms-conditions h3 {
  font-size: 20px;
  font-weight: bold;
}
.lastSection .terms-conditions p {
  font-size: 12px;
  line-height: 15px;
}
.lastSection .terms-conditions ul {
  font-size: 11px;
}
.lastSection .terms-conditions .disclaimer p {
  font-size: 8px;
}
