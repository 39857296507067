.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b9a16b;
  height: 100vh;
  width: 100vw;
}
.Loader .boxFrame {
  width: 100px;
  height: 100px;
  position: relative;
  perspective: 800px;
  animation: animate 0.75s linear;
}
.Loader .boxFrame .box1,
.Loader .boxFrame .box2,
.Loader .boxFrame .box3 {
  width: calc(var(--i) * 50px);
  height: calc(var(--i) * 50px);
  border: 5px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
}
.Loader .boxFrame .box1 {
  animation: animate1 1.25s linear infinite;
}
.Loader .boxFrame .box2 {
  animation: animate2 1s linear infinite;
}
.Loader .boxFrame .box3 {
  animation: animate3 0.75s linear infinite;
}
@keyframes animate {
  0% {
    transform: scale(0) rotateX(180deg);
  }
  50% {
    transform: scale(0.5) rotateX(90deg);
  }
  100% {
    transform: scale(1) rotateX(0deg);
  }
}
@keyframes animate1 {
  0% {
    transform: translate(-50%, -50%) rotateY(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateY(-180deg);
  }
}
@keyframes animate2 {
  0% {
    transform: translate(-50%, -50%) rotateY(-180deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateY(0deg);
  }
}
@keyframes animate3 {
  0% {
    transform: translate(-50%, -50%) rotateY(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateY(-180deg);
  }
}
