.footer {
  background: #1a1a1a;
  width: 100vw;
  padding: 50px 100px 0px 100px;
}
.footer .footerLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  color: #bbb;
}
.footer .footerLinks .footerContact {
  min-width: 250px;
  text-align: center;
}
.footer .footerLinks .footerContact h5,
.footer .footerLinks .footerLink1 ul li:first-child,
.footer .footerLinks .footerLink2 ul li:first-child,
.footer .footerLinks .footerLink3 ul li:first-child,
.footer .footerLinks .footerLink4 ul li:first-child {
  font-size: 14px;
  color: #ff7f23;
  /* color: #3b71ca; */
  font-weight: bold;
  padding-bottom: 15px;
  margin-top: 20px;
}
.footer .footerLinks .footerContact .contact {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
.footer .footerLinks .footerContact h6 {
  font-size: 14px;
  color: #bbb;
  font-weight: bold;
}
.footer .footerLinks .footerContact p {
  font-size: 12px;
  color: #bbb;
}
.footer .footerLink1,
.footer .footerLink2,
.footer .footerLink3,
.footer .footerLink4 {
  min-width: 100px;
}
.footer .footerLinks .footerLink1 ul li,
.footer .footerLinks .footerLink2 ul li,
.footer .footerLinks .footerLink3 ul li,
.footer .footerLinks .footerLink4 ul li {
  font-size: 14px;
  color: #bbb;
}
.footer .signUpFooter {
  padding: 20px;
  border-top: 1px solid #bbb5;
  border-bottom: 1px solid #bbb5;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.footer .signUpFooter span:first-child {
  font-size: 18px;
  display: block;
  text-align: center;
  color: #fff;
}
.footer .signUpFooter .inputText {
  display: flex;
  height: 50px;
}
.footer .signUpFooter .inputText input[type="text"] {
  border: none;
  outline: none;
  font-size: 8px;
  padding: 5px 5px 5px 20px;
  margin: 0;
  width: 200px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.footer .signUpFooter .inputText input[type="submit"] {
  border: none;
  outline: none;
  font-size: 12px;
  margin: 0;
  padding: 5px 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Add this CSS to your stylesheet */
.footer {
  text-align: center;
  padding: 20px;
}

.footerLinks {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  align-items: flex-start;
}

.footerLink1,
.footerLink3 {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.socialMedia {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Mobile-specific styles */
/* @media (max-width: 768px) {
  .footer {
    text-align: center;
  }

  .footerLinks {
    flex-direction: column;
    align-items: center;
  }

  .footerLink1 ul,
  .footerLink3 ul {
    padding: 0;
    list-style: none;
  }

  .footerLink1 li,
  .footerLink3 li {
    margin: 5px 0;
  }

  .socialMedia a {
    margin: 0 5px;
  }
} */
