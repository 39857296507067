.carousel-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  height: auto; /* Full viewport height */
  width: 100vw;
}

.carousel {
  display: flex;
  width: 90%;
  height: 80%;
  padding: 5%;
}

.FAQs {
  margin: 50px 0px;
  padding: 50px;
  background: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.FAQs h3 {
  font-size: 25px;
  font-weight: bold;
}
.FAQs h5 {
  font-size: 16px;
}
.FAQs h6 {
  font-size: 15px;
  color: rgb(238, 79, 16);
  /* color: #ff7f23; */
}
.FAQs .categoryCards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.FAQs .categoryCards .categoryCard {
  width: 400px;
  height: 450px;
  background: #fff;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  overflow: hidden;
}
.FAQs .categoryCards .categoryCard .categoryCardImg {
  width: 100%;
  height: 200px;
}
.FAQs .categoryCards .categoryCard .categoryCardImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.FAQs .categoryCards .categoryCard .categoryCardText {
  padding: 20px;
  height: 100%;
  position: relative;
}
.FAQs .categoryCards .categoryCard .categoryCardText h4 {
  font-size: 20px;
  font-weight: bold;
}
.FAQs .categoryCards .categoryCard .categoryCardText ul li {
  list-style-type: "✔️";
  font-size: 16px;
  padding-left: 10px;
  color: #555;
}
.FAQs .categoryCards .categoryCard .categoryCardText a {
  font-size: 14px;
  color: #ff7f23;
  text-decoration: underline;
}
.featured {
  width: 100vw;
  height: 600px;
  background: url("../utils/featured.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
