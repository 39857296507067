* {
  /* border: 1px solid red; */
}
.login .main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding-bottom: 50px;
}
.login .sub-main {
  width: 50vw;
  max-width: 700px;
  min-width: 280px;
  background: #fff;
  padding: 20px;
  margin: 100px 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);
}
.login .sub-main ul {
  list-style: none;
  width: 100%;
}
.login .sub-main ul li {
  width: 50%;
}
.login .sub-main ul li button {
  height: 50px;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
}
.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  /* min-width: 250px; */
}
.loginForm div p {
  font-size: 24px;
  font-weight: bold;
}
.loginForm .signInGoogle {
  width: 250px;
  background: #eee;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.loginForm .signInGoogle .colorBar {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -20px;
  left: -35px;
  background: #e93e30;
  z-index: 1;
  border-radius: 50%;
  transition: 2s;
}
.loginForm .signInGoogle .googleLogo {
  height: 100%;
  width: 15%;
  background: #e93e30;
  font-size: 12px;
  display: inline-block;
  color: #fff;
  padding: 13px;
  z-index: 10;
  position: relative;
}
.loginForm .signInGoogle .googleText {
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 85%;
  text-align: center;
  font-size: 16px;
  transition: 1s;
}
.loginForm .signInGoogle:hover {
  cursor: pointer;
}
.loginForm .signInGoogle:hover .colorBar {
  width: 350px;
  height: 100px;
  top: -30px;
  transition: 1s;
}
.loginForm .signInGoogle:hover .googleText {
  transition: 1s;
  color: #fff;
}
.loginForm .fields input {
  width: 100%;
  height: 50px;
  max-width: 700px;
  min-width: 300px;
  background: #eee;
  font-size: 12px;
  border: none;
  outline: none;
}
.loginForm .fields input:focus {
  border: 1px solid #ff7f23;
}
.loginForm button {
  width: 50%;
  height: 50px;
  max-width: 700px;
  /* min-width: 300px; */
  background: #ff7f23;
  color: #000;
  font-size: 14px;
  /* font-weight: bold; */
  border: none;
  margin: 10px;
  /* text-transform: lowercase; */
  border-radius: 10px;
}

.loginForm button:hover {
  color: #fff;
  background: #ff7f23dd;
}

.loginForm button:disabled {
  background-color: darkgray;
  color: White;
}

.otpButton {
  width: 50%;
  height: 50px;
  max-width: 700px;
  min-width: 300px;
  background: #ff7f23;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  border: none;
  margin: 10px;
  text-transform: lowercase;
  border-radius: 10px;
  text-align: "center";
  padding: "5px";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  cursor: pointer;
}

/* .loginForm .fields input .btnn { */
#otp-button {
  width: 50%;
  border: 2px solid orange;
  color: orange;
  background-color: white;
}
#otp-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}
#otp-button:disabled {
  background-color: grey;
  border: 2px solid rgb(200, 186, 186);
  color: White;
}

.formField {
  /* display: flex; */
  /* width: 90%; */
  height: 50px;
  max-width: 700px;
  min-width: 300px;
  background: #eee;
  font-size: 12px;
  border: none;
  outline: none;
}

@media screen and (max-width: 600px) {
  .loginForm .fields input {
    min-width: 200px;
  }
  .formField {
    min-width: 200px;
    /* width: 80%; */
  }
  .loginForm button {
    min-width: 200px;
  }
  .otpButton {
    min-width: 200px;
  }
}
